<template>
  <v-container fluid fill-height class="pa-0 ma-0">
    <div class="login-backgroud-img"></div>

    <v-card v-if="isError" id="login-card" class="elevation-1 login-card body-2" color="blue-grey darken-4" dark>
      <DialogError text="ユーザー登録に失敗しました。リンクが間違っている,またはすでに本登録が済んでいる可能性があります。" toTop="true" isReload="false" />
    </v-card>

    <v-card v-else-if="!isComplete" id="login-card" class="elevation-1 login-card body-2" color="primary" dark>
      <v-card-text>
        登録中...
        <br />
        <v-progress-linear indeterminate color="white"></v-progress-linear>
      </v-card-text>
    </v-card>

    <v-card v-else class="login-card">
      <DialogComplete text="ユーザー作成が完了しました。" toTop="true" />
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";

const DialogError = () => import("@/components/DialogError.vue");
const DialogComplete = () => import("@/components/DialogComplete.vue");

export default {
  components: { DialogComplete, DialogError },
  name: "CreateAccountConfirm",
  data: () => ({
    loading: false,
    isComplete: false,
    isError: false,
    token: null,
    sign: null,
  }),

  created() {
    this.createAccountConfirm();
  },

  methods: {
    async createAccountConfirm() {
      try {
        this.loading = true;

        const response = await axios.put(
          `${process.env.VUE_APP_URL}account`,
          {
            token: this.$route.query.token,
            sign: this.$route.query.sign,
          },
          {
            headers: {
              "X-Api-Key": process.env.VUE_APP_KEY,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.results === "complete") {
          this.loading = false;
          this.isComplete = true;
        } else {
          this.loading = false;
          this.isError = true;
        }
      } catch (err) {
        this.loading = false;
        this.isError = true;
        console.log(err);
      }
    },
  },
};
</script>
